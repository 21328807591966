import React from "react";
import bannerTitle from "../img/title.svg";
import bannerButton from "../img/banner-button.svg";
import bannerBg2 from "../img/banner-bg-2.png";
import bannerLogo2 from "../img/banner-logo-2.png";
import bannerTitle2 from "../img/second-banner-title.svg";
import bannerLogo3 from "../img/banner-logo-3.png";
import bannerTitle3 from "../img/four-banner-title.svg";
import bannerChart from "../img/banner-chart.png";
import bannerTitle4 from "../img/banner-title-1.svg";
import telegramIcon from "../img/telegram.png";
import twitterIcon from "../img/twitter.png";
import AnimationLogo from "./AnimationLogo";
import bannerLogo5 from "../img/banner-logo-5.png";

const MainContainer = ({ onOpen }) => {
  return (
    <div className="main-container" id="home">
      <div className="first-banner">
        <div className="banner-left">
          <div className="banner-title">
            <img src={bannerTitle} alt="3w3w" />
          </div>
          <div className="banner-text">
            <p>
              Nowadays, 3w3w bravely stands on the opposite side of all meme
              projects. 3w3w turns the expression "MEME" upside down to "3W3W"
              and explores the other side of the meme world. Unlike other
              tokens, 3w3w does not have a grandiose narrative and exaggerated
              profile, we are just taking a different approach, hoping to make
              everyone realize that the world of blockchain should not lack
              innovation.
            </p>
            <p>
              Turning the existing world upside down will create a brand new
              world, and the 3w3w will also be committed to inherit the
              innovative spirit of the blockchain ecosystem and create a young
              and dynamic community.
            </p>
          </div>
          <div className="button-container">
            <div className="banner-button">
              <a href="/">
                <img src={bannerButton} alt="buy" className="button-bg" />
                <span className="button-text">Buy</span>
              </a>
            </div>
            <div className="banner-token-button" onClick={onOpen}>
              Token Address
            </div>
          </div>
        </div>
        <div className="banner-right">
          <AnimationLogo />

          <div className="button-container">
            <div className="banner-button">
              <a href="/">
                <img src={bannerButton} alt="buy" className="button-bg" />
                <span className="button-text">Buy</span>
              </a>
            </div>
            <div className="banner-token-button" onClick={onOpen}>
              Token Address
            </div>
          </div>
        </div>
        <div class="trapezoid"></div>
      </div>
      <div className="second-banner" id="background">
        <div className="banner-bg">
          <img src={bannerBg2} alt="" />
        </div>
        <div className="banner-left">
          <div className="banner-title">
            <img src={bannerTitle2} alt="background" />
          </div>

          <div className="banner-text">
            The meme world is filled with a large number of homogeneous tokens.
            Each community emphasizes the principle of "innovation" and each
            project strives to use different profiles to demonstrate the
            so-called "distinction". However, current meme projects all stand on
            one side of the world. This side is filled with copying and
            plagiarism which makes no significant difference between these
            projects.
          </div>
        </div>
        <div className="banner-right">
          <img src={bannerLogo2} className="banner-logo-2" alt="banner-logo" />
          <img src={bannerLogo5} className="banner-logo-3" alt="banner-logo" />
        </div>
      </div>
      <div className="third-banner">
        <img src={bannerLogo3} alt="banner-logo" />
      </div>
      <div className="four-banner" id="tokenomics">
        <div className="banner-title">
          <img src={bannerTitle3} alt="Tokenomics" srcset="" />
        </div>
        <div className="banner-des">
          <p>
            12 billion 3w3w tokens will be minted in total. They serve a form of
            our cultural expression, a reward for our passionate community and a
            motivation for our relentless contributors.
          </p>
          <p>3w3w tokens will be distributed into 6 main pools: </p>
        </div>
        <div className="banner-container">
          <div className="banner-left">
            <img src={bannerChart} alt="chart" />
          </div>
          <div className="banner-right">
            <div className="banner-chart-item">
              <span className="serial-number">1</span>
              <span className="chart-text">
                Ecosystem 35% reserved to build the ecosystem consistently
              </span>
            </div>
            <div className="banner-chart-item">
              <span className="serial-number">2</span>
              <span className="chart-text">
                Contributors 30% for the partners that build with us 
              </span>
            </div>
            <div className="banner-chart-item">
              <span className="serial-number">3</span>
              <span className="chart-text">
                Community airdrop 15% will be distributed to active community
                participants 
              </span>
            </div>
            <div className="banner-chart-item">
              <span className="serial-number">4</span>
              <span className="chart-text">
                Private sale 10% distributed to external investors and holders
                via private sale 
              </span>
            </div>
            <div className="banner-chart-item">
              <span className="serial-number">5</span>
              <span className="chart-text">
                Advisory 5% for the counseling we need to pull through 
              </span>
            </div>
            <div className="banner-chart-item">
              <span className="serial-number">6</span>
              <span className="chart-text">
                Cex/Dex liquidity 5% for the initial liquidity of exchange
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="five-banner">
        <div className="banner-title">
          <img src={bannerTitle4} alt="3w3w" srcset="" />
        </div>
        <div className="banner-link">
          <div className="banner-link-item">
            <a
              href="https://x.com/3w3w_realmeme?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegramIcon} className="link-icon" alt="Telegram" />
            </a>
          </div>
          <div className="banner-link-item">
            <a
              href="https://twitter.com/3w3w_meme"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} className="link-icon" alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
