import React from "react";
import closeIcon from "../img/close.svg";
import copyIcon from "../img/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

const TokenModal = ({ onClose }) => {
  return (
    <div className="token-modal-container">
      <div className="modal-shadow" onClick={onClose}></div>
      <div className="modal-content">
        <div className="modal-header">
          <div className="title-text">3w3w Token Address</div>
          <div className="title-close" onClick={onClose}>
            <img src={closeIcon} alt="close" />
          </div>
        </div>
        <div className="modal-body">
          <span>Do7Rwp6Bq7v43ZuEeKZZfB96841AKZHxMLqtYFegaJYw</span>
        </div>
        <div className="modal-footer">
          <CopyToClipboard text="Do7Rwp6Bq7v43ZuEeKZZfB96841AKZHxMLqtYFegaJYw">
            <div className="footer-button">
              <img src={copyIcon} alt="copy" />
              <span>Copy</span>
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default TokenModal;
