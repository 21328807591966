import "./css/App.css";
import "./css/mobile.css";
import React, { useState } from "react";
import Header from "./components/Header";
import MainContainer from "./components/MainContainer";
import Footer from "./components/Footer";
import TokenModal from "./components/TokenModal";

const App = () => {
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="app">
      <Header />
      <MainContainer onOpen={openModal} />
      <Footer />
      {open && <TokenModal onClose={closeModal} />}
    </div>
  );
};

export default App;
