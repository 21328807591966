import React from "react";
import logo from "../img/logo.png";
import logoText from "../img/logo-text.png";
import telegramIcon from "../img/telegram.png";
import twitterIcon from "../img/twitter.png";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <header className="app-header">
      <div className="header-container">
        <div className="header-left">
          <div className="header-logo">
            <img src={logo} className="app-logo" alt="logo" />
            <img src={logoText} className="app-logo-text" alt="logo" />
          </div>
          <nav className="main-nav">
            <ul className="nav-list">
              <li className="nav-item">
                <Link
                  className="link-item"
                  activeClass="active"
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link-item"
                  activeClass="active"
                  to="background"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Background
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="link-item"
                  activeClass="active"
                  to="tokenomics"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Tokenomics
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-right">
          <ul className="nav-list">
            <li className="nav-item">
              <a
                href="https://x.com/3w3w_realmeme?s=20"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegramIcon} className="link-icon" alt="Telegram" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://twitter.com/3w3w_meme"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterIcon} className="link-icon" alt="Twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
