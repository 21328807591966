import React, { useEffect, useState } from "react";
import bannerLogo from "../img/banner-logo-1.gif";
import bannerLogo4 from "../img/banner-logo-4.gif";

const AnimationLogo = () => {
  const [imgControl, setImgControl] = useState(1);

  const executeEveryThreeSeconds = () => {
    let count = 0;

    const intervalId = setInterval(() => {
      count++;
      setImgControl(count);
      if (count === 4) {
        setImgControl(count);
        count = 0;
      }
    }, 4000);

    return intervalId;
  };

  useEffect(() => {
    const intervalId = executeEveryThreeSeconds();
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div>
      {(imgControl === 1 || imgControl === 2) && (
        <img src={bannerLogo} className="banner-logo" alt="banner-logo" />
      )}
      {(imgControl === 3 || imgControl === 4) && (
        <img src={bannerLogo4} className="banner-logo" alt="banner-logo" />
      )}
    </div>
  );
};

export default AnimationLogo;
